/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

import { initializeGlobalProps } from '@hmn/coolinarika-web-core/helpers'
import {
    useAnalytics,
    useLocalStorage,
    usePageUrl,
    useUser,
    useUserTasteProfile
} from '@hmn/coolinarika-web-core/hooks'
import { InfiniteLoadLoader, InfiniteLoadTrigger, useListElastic } from '@hmn/coolinarika-web-core/hooks/useElastic'
import { storeWrapper } from '@hmn/coolinarika-web-core/store'

import { ChatBot } from '@hmn/coolinarika-web-ui/components'
import { HeadingSectionMain } from '@hmn/coolinarika-web-ui/components/HeadingSection/components/Main'
import { ListingSeo } from '@hmn/coolinarika-web-ui/components/Listing/components'
import { FooterSeparator } from '@hmn/coolinarika-web-ui/components/Misc/components'
import { GridLayoutController } from '@hmn/coolinarika-web-ui/controllers'

import tasteMachineConfig from '@hmn/taste-machine-config'

import { Motion } from '@web/components'
import { Column, Row } from '@web/layouts'

const getHomepageListParams = (userTasteProfileData, abTestVariationId) => {
    // const isAbTest = !!userTasteProfileData && !!abTestVariationId && abTestVariationId !== 'N/A'
    const isAbTest = false

    return {
        resource: 'naslovnica',
        endpoint: isAbTest ? 'recommended-recipes' : undefined,
        method: isAbTest ? 'POST' : 'GET',
        body: isAbTest ? { tasteProfile: userTasteProfileData } : undefined,
        params: isAbTest ? { query: { abTestVariationId } } : { limit: 120 },
        enabled: !!userTasteProfileData && !!abTestVariationId,
        settings: {
            cacheTime: 600000 // 10 min
        }
    }
}

const title = 'Hej, inspiriraj se i pripremi nešto dobro!'

const Index = ({ session: sessionFromServerSideProps, abTestVariationIdParam, ...rest }) => {
    const uid = useUIDSeed()

    const { isLoggedIn, profile: userData = {} } = useUser()

    const url = usePageUrl()

    const [isFirstAppLaunch, setIsFirstAppLaunch] = useLocalStorage('is-first-app-launch', true)

    const { eventWithTrackingData: trackIosEvent } = useAnalytics('coolinarika.ios')

    useEffect(() => {
        const isAppleMobile = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)

        if (isFirstAppLaunch && isAppleMobile) {
            setIsFirstAppLaunch(false)
            trackIosEvent({ type: 'first-launch' })

            if (navigator.standalone) {
                trackIosEvent({ type: 'download' })
            }
        }
    }, [])

    const {
        userTasteProfileData,
        abTestVariationId,
        loading: userTasteProfileLoading
    } = useUserTasteProfile(tasteMachineConfig.experimentConfigHomepage)

    const { pages, ref, isLoading, isFetching, isFetchingNextPage, hasNextPage } = useListElastic(
        getHomepageListParams(userTasteProfileData, abTestVariationIdParam || abTestVariationId)
    )

    const showSpinner = isLoading || userTasteProfileLoading || ((isFetching || isFetchingNextPage) && hasNextPage)

    const modifiedPageData = useMemo(() => {
        const newPages = [...pages].map(page => {
            const pageData = page?.map(pageItem => {
                if (pageItem?.taxonomy?.name === 'menus' && pageItem?.type === 'taxon') {
                    return {
                        ...pageItem,
                        type: 'menu'
                    }
                }
                return pageItem
            })
            return pageData
        })

        return newPages
    }, [pages])

    const username = userData?.display_name || userData?.username || sessionFromServerSideProps?.user?.name

    const seoItem = useMemo(
        () => ({
            ...modifiedPageData,
            title,
            url,
            articles: modifiedPageData
        }),
        [modifiedPageData]
    )

    const eventViewTrackings = useRef([])

    const onViewEventTracking = useCallback((viewTracking, item) => {
        if (!eventViewTrackings.current.includes(item.variation)) {
            const payload = { eventCategory: 'Homepage - preporuke recepata' }

            // hardcode eventVariation to Original since taste machine is currently disabled
            // if (Number.isInteger(Number(item.variation))) {
            //     payload.eventVariation = `Eksperiment: ${
            //         tasteMachineConfig.experimentConfigHomepage[item.variation].label
            //     }`
            // } else {
            //     payload.eventVariation = 'Original'
            //     eventViewTrackings.current.push(...['Original', 'N/A'])
            // }

            payload.eventVariation = 'Original'
            eventViewTrackings.current.push(...['Original', 'N/A'])
            eventViewTrackings.current.push(item.variation)

            viewTracking(payload, 'coolinarika.recommendations_hp_view')
        }
    }, [])

    const titleWithUser = useMemo(
        () =>
            isLoggedIn && username
                ? `Hej ${username}, <span class="accentPrimary">inspiriraj se</span><br /> i pripremi nešto dobro!`
                : '<span class="accentPrimary">Inspiriraj se</span><br /> i pripremi nešto dobro!',
        [isLoggedIn, username]
    )

    return (
        <Motion.Route {...rest}>
            <ChatBot />
            <ListingSeo item={seoItem} />
            <Row variant="contentFeed.top">
                <Column variant="grid.12">
                    <Column variant="grid.12.col.12x1">
                        <HeadingSectionMain title={titleWithUser} titleDecoration largeLineHeight />
                    </Column>
                </Column>
            </Row>

            {modifiedPageData?.map((homePage, groupIndex) => (
                <GridLayoutController
                    key={uid(groupIndex)}
                    items={homePage}
                    groupIndex={groupIndex}
                    onViewEventTracking={onViewEventTracking}
                    noRow
                />
            ))}

            {!showSpinner && <InfiniteLoadTrigger ref={ref} />}
            {showSpinner && <InfiniteLoadLoader />}
            <FooterSeparator />
        </Motion.Route>
    )
}

Index.propTypes = {
    session: PropTypes.shape({}),
    abTestVariationIdParam: PropTypes.string
}
Index.defaultProps = {
    session: undefined,
    abTestVariationIdParam: null
}
export const getServerSideProps = storeWrapper.getServerSideProps(async ctx => {
    const { query } = ctx

    const initialProps = await initializeGlobalProps({ ...ctx })

    return {
        props: {
            ...initialProps,
            abTestVariationIdParam: query?.abTestVariationId || null
        }
    }
})

export default Index
