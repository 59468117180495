const tasteMachineConfig = {
    isEnabled: false,
    matchBy: {
        foodType: ['food.type', 'food.subtype'],
        ingredients: ['ingredients.significant'],
        categories: ['categories.*.title']
    },
    features: {
        Ingredient: 'ingredients.classified',
        FoodType: 'food.type.keyword',
        FoodSubType: 'food.subtype.keyword'
    },
    experimentationVolume: 20,
    experimentConfigHomepage: [
        {
            label: 'A - By ingredients, 1.5 popularity, 2.5 seasonality, 1000d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment A: Personalize by Ingredients (10, 20); Rank by score, popularity (1.5), seasonality (2.5), date decay (exp, 1000d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: true,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: false,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: false,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 1.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '1000d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'B - By food, 1.5 popularity, 2.5 seasonality, 1000d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment B: Personalize by FoodType (5, 15), FoodSubType (10, 15); Rank by score, popularity (1.5), seasonality (2.5), date decay (exp, 1000d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: false,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: true,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: true,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 1.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '1000d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'C - By ingredients and food, 1.5 popularity, 2.5 seasonality, 1000d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment C: Personalize by Ingredients (10, 20), FoodType (5, 15), FoodSubType (10, 15); Rank by score, popularity (1.5), seasonality (2.5), date decay (exp, 1000d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: true,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: true,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: true,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 1.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '1000d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'D - By ingredients, 1.5 popularity, 5.0 seasonality, 365d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment D: Personalize by Ingredients (10, 20); Rank by score, popularity (1.5), seasonality (5.0), date decay (exp, 365d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: true,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: false,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: false,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 1.5
                },
                seasonality: {
                    enabled: true,
                    weight: 5.0
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '365d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'E - By food, 1.5 popularity, 5.0 seasonality, 365d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment E: Personalize by FoodType (5, 15), FoodSubType (10, 15); Rank by score, popularity (1.5), seasonality (5.0), date decay (exp, 365d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: false,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: true,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: true,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 1.5
                },
                seasonality: {
                    enabled: true,
                    weight: 5.0
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '365d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'F - By ingredients and food, 1.5 popularity, 5.0 seasonality, 365d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment F: Personalize by Ingredients (10, 20), FoodType (5, 15), FoodSubType (10, 15); Rank by score, popularity (1.5), seasonality (5.0), date decay (exp, 365d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: true,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: true,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: true,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 1.5
                },
                seasonality: {
                    enabled: true,
                    weight: 5.0
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '365d',
                    decay: 0.7
                }
            }
        }
    ],
    experimentConfig: [
        {
            label: 'A - By ingredients, 0.5 popularity, 2.5 seasonality',
            description:
                'Experiment A: Personalize by Ingredients (10, 20); Rank by score, popularity (0.5), seasonality (2.5)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: true,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: false,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: false,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 0.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: false,
                    function: 'decayDateExp',
                    scale: '1825d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'B - By food, 0.5 popularity, 2.5 seasonality',
            description:
                // eslint-disable-next-line max-len
                'Experiment B: Personalize by FoodType (5, 15), FoodSubType (10, 15); Rank by score, popularity (0.5), seasonality (2.5)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: false,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: true,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: true,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 0.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: false,
                    function: 'decayDateExp',
                    scale: '1825d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'C - By ingredients and food, 0.5 popularity, 2.5 seasonality',
            description:
                // eslint-disable-next-line max-len
                'Experiment C: Personalize by Ingredients (10, 20), FoodType (5, 15), FoodSubType (10, 15); Rank by score, popularity (0.5), seasonality (2.5)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: true,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: true,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: true,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 0.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: false,
                    function: 'decayDateExp',
                    scale: '1825d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'D - By ingredients, 0.5 popularity, 2.5 seasonality, 1825d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment D: Personalize by Ingredients (10, 20); Rank by score, popularity (0.5), seasonality (2.5), date (exp, 1825d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: true,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: false,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: false,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 0.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '1825d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'E - By food, 0.5 popularity, 2.5 seasonality, 1825d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment E: Personalize by FoodType (5, 15), FoodSubType (10, 15); Rank by score, popularity (0.5), seasonality (2.5), date (exp, 1825d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: false,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: true,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: true,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 0.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '1825d',
                    decay: 0.7
                }
            }
        },
        {
            label: 'F - By ingredients and food, 0.5 popularity, 2.5 seasonality, 1825d/0.7 date decay',
            description:
                // eslint-disable-next-line max-len
                'Experiment F: Personalize by Ingredients (10, 20), FoodType (5, 15), FoodSubType (10, 15); Rank by score, popularity (0.5), seasonality (2.5), date (exp, 1825d, 0.7)',
            weight: 10,
            features: {
                Ingredient: {
                    enabled: true,
                    weight: 10,
                    limit: 20
                },
                FoodType: {
                    enabled: true,
                    weight: 5,
                    limit: 15
                },
                FoodSubType: {
                    enabled: true,
                    weight: 10,
                    limit: 15
                }
            },
            ranking: {
                score: {
                    enabled: true
                },
                popularity: {
                    enabled: true,
                    weight: 0.5
                },
                seasonality: {
                    enabled: true,
                    weight: 2.5
                },
                date_decay: {
                    enabled: true,
                    function: 'decayDateExp',
                    scale: '1825d',
                    decay: 0.7
                }
            }
        }
    ],
    excludedFromExperimentCookieValue: 'N/A',
    COOLINARIKA_TASTE_PROFILE_DATA: 'coolinarika-taste-profile-data',
    COOLINARIKA_TASTE_PROFILE_FETCH_DATE: 'coolinarika-taste-profile-fetch-date',
    COOLINARIKA_TASTE_PROFILE_LIFE: 10, // minutes
    COOLINARIKA_PSEUDO_USER_ID_KEY: 'coolinarika-pseudo-user-id'
}

export default tasteMachineConfig
