/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { destroyCookie, parseCookies, setCookie } from 'nookies'

import tasteMachineConfig from '@hmn/taste-machine-config'

import { globalSettings } from '../settings'
import useUser from './useUser'

const { apiBaseUrl } = globalSettings

const cookieExpiry = 30 * 24 * 60 * 60 // 30 days
const abTestCookieKey = 'abTestSrpRecomendations'

const {
    COOLINARIKA_PSEUDO_USER_ID_KEY,
    COOLINARIKA_TASTE_PROFILE_DATA,
    COOLINARIKA_TASTE_PROFILE_FETCH_DATE,
    COOLINARIKA_TASTE_PROFILE_LIFE
} = tasteMachineConfig

/**
 * @typedef {typeof tasteMachineConfig.experimentConfig} Experiments
 * @typedef {typeof tasteMachineConfig.experimentConfigHomepage} HomepageExperiments
 */

const getRandomIntInclusive = (minNumber, maxNumber) => {
    const min = Math.ceil(minNumber)
    const max = Math.floor(maxNumber)
    // eslint-disable-next-line security-node/detect-insecure-randomness
    return Math.floor(Math.random() * (max - min + 1) + min) // The maximum is inclusive and the minimum is inclusive
}

/**
 * Get pseudoUserId and store it in cookie
 *
 * @param {string} apiBase
 * @returns {Promise<string>}
 */
// const fetchPseudoUserId = async apiBase => {
//     const response = await fetch(`${apiBase}/streaming-etl/user`)
//     const data = await response.json()

//     if (!response.ok) {
//         throw new Error(data?.message)
//     }

//     const { id: pseudoUserId } = data

//     // Preserve pseudo ID in cookies
//     setCookie(null, COOLINARIKA_PSEUDO_USER_ID_KEY, pseudoUserId, {
//         maxAge: cookieExpiry
//     })

//     return pseudoUserId
// }

/**
 * - gets user taste profile data, stores it to localstorage and sets time of fetching to cookie
 *
 * @param {string} id user id (or pseudo id)
 * @param {string} apiBase
 * @returns {object} user taste profile data
 */
// const getUserTasteProfile = async (id, apiBase) => {
//     // const testID = 'c22dfc6c-7c4c-11eb-930f-d622150c6dad' // test user id with taste profile data
//     const response = await fetch(`${apiBase}/feed/recept/user-taste-profile/${id}`)
//     const data = await response.json()

//     if (!response.ok) {
//         throw new Error(data?.message)
//     }

//     window.localStorage.setItem(COOLINARIKA_TASTE_PROFILE_DATA, JSON.stringify(data))

//     setCookie(null, COOLINARIKA_TASTE_PROFILE_FETCH_DATE, dayjs().format('YYYY-MM-DD HH:mm:ss'), {
//         maxAge: cookieExpiry,
//         path: '/'
//     })

//     return data
// }

/**
 * Get taste machine experiment variation ID (abTestVariationId), store to cookie and return it
 *
 * @param {string} abTestCookieValue
 * @param {Experiments | HomepageExperiments} experiments
 * @param {number} [experimentProbability=20] // DEFAULT 20
 * @returns {abTestVariationId: string}
 */
export const getAbTestVariationId = (abTestCookieValue, experiments, experimentProbability = 20) => {
    const percentUsersToInclude = experimentProbability
    const numAvailableVariations = experiments.length - 1 // variations starts from 0
    const randomPercentageForInclusion = getRandomIntInclusive(1, 100)
    const userIsIncludable = randomPercentageForInclusion <= percentUsersToInclude
    let abTestVariationId = abTestCookieValue

    if (!abTestVariationId) {
        if (userIsIncludable) {
            abTestVariationId = getRandomIntInclusive(0, numAvailableVariations)
        } else {
            abTestVariationId = tasteMachineConfig.excludedFromExperimentCookieValue
        }

        setCookie(null, abTestCookieKey, abTestVariationId, {
            maxAge: cookieExpiry,
            path: '/'
        })
    }

    return abTestVariationId
}

/**
 * - Gets user taste profile data and taste machine experiment variation ID (abTestVariationId)
 * * Because of event tracking, hook should only be used directly on pages (not page components)
 *
 * @param {Experiments | HomepageExperiments} experiments
 * @param {number=} experimentProbability
 * @returns {{userTasteProfileData: any[], abTestVariationId: string, loading: boolean}}
 */
const useUserTasteProfile = (experiments, experimentProbability) => {
    // const { eventTracking } = useAnalytics('set')
    const { profile: userData = {} } = useUser()
    const { id: loggedInUserId } = userData

    const [abTasteProfile, setAbTasteProfile] = useState()
    const [loading, setLoading] = useState(true)

    // Return default values if tasteMachineConfig.isEnabled is false
    const defaultValues = {
        userTasteProfileData: [],
        abTestVariationId: -1,
        isExperiment: false,
        loading: false
    }

    const setUserTasteProfile = useCallback(async () => {
        if (!tasteMachineConfig.isEnabled) {
            setAbTasteProfile(defaultValues)
            return
        }

        const cookies = parseCookies()

        const abTestVariationId = getAbTestVariationId(cookies[abTestCookieKey], experiments, experimentProbability)

        // let userIdValue = loggedInUserId
        const userIdValue = null

        if (!userIdValue) {
            // In dev/staging environments, skip fetching taste profile data for guests
            setAbTasteProfile({ userTasteProfileData: [], abTestVariationId })
            return
            // try {
            //     // IF no logged in user exists, generate pseudo ID & persist it for future sessions
            //     const userIdFromCookies = cookies[COOLINARIKA_PSEUDO_USER_ID_KEY]

            //     if (!userIdFromCookies) {
            //         userIdValue = await fetchPseudoUserId(apiBaseUrl)
            //     } else {
            //         userIdValue = userIdFromCookies
            //     }
            // } catch (error) {
            //     // If pseudo user id fetch fails return default tasteProfile data
            //     setAbTasteProfile({ userTasteProfileData: [], abTestVariationId })
            //     return
            // }
        }
        // When user logins remove guest user traces (pseudo user ID)
        destroyCookie(null, COOLINARIKA_PSEUDO_USER_ID_KEY)

        const storedTasteProfileString = window.localStorage.getItem(COOLINARIKA_TASTE_PROFILE_DATA)

        // pass `null` if cookie does not exist, otherwise `undefined` returns a valid date
        const tasteProfileLastFetchDate = dayjs(cookies[COOLINARIKA_TASTE_PROFILE_FETCH_DATE] || null)

        const now = dayjs()
        const nowWithExtraTime = dayjs(tasteProfileLastFetchDate).add(COOLINARIKA_TASTE_PROFILE_LIFE, 'm')

        const tasteProfileLastFetchExpired =
            !tasteProfileLastFetchDate.isValid() || nowWithExtraTime.diff(now, 'minute', true) < 0

        if (!storedTasteProfileString || tasteProfileLastFetchExpired) {
            // try {
            //     const userTasteProfileData = await getUserTasteProfile(userIdValue, apiBaseUrl)
            //     setAbTasteProfile({ userTasteProfileData, abTestVariationId })
            //     return
            // } catch (error) {
            //     setAbTasteProfile({ userTasteProfileData: [], abTestVariationId })
            // }
            setAbTasteProfile({ userTasteProfileData: [], abTestVariationId })
        } else {
            const storedTasteProfileData = JSON.parse(storedTasteProfileString)
            setAbTasteProfile({ userTasteProfileData: storedTasteProfileData, abTestVariationId })
        }
    }, [experiments, loggedInUserId, apiBaseUrl])

    useEffect(() => {
        if (!tasteMachineConfig.isEnabled) {
            setAbTasteProfile(defaultValues)
            setLoading(false)
            return
        }

        setLoading(true)

        setUserTasteProfile().finally(() => {
            setLoading(false)
        })
    }, [setUserTasteProfile])

    const { userTasteProfileData, abTestVariationId } = abTasteProfile || {}

    const isExperiment = useMemo(() => Number.isInteger(Number(abTestVariationId)), [abTestVariationId])

    /** @TODO Uncomment when GTM is updated and deployed. */
    // useEffect(() => {
    //     /**
    //      * Track event only if user is participating in the experiment (when abTestVariationId exists).
    //      */
    //     if (!Number.isInteger(Number(abTestVariationId))) {
    //         return
    //     }
    //     eventTracking({
    //         variation: tasteMachineConfig.experimentConfig[abTestVariationId].label
    //     })
    // }, [abTestVariationId])

    return { userTasteProfileData, abTestVariationId, isExperiment, loading }
}

export default useUserTasteProfile
