import { useMemo } from 'react'
// import { useUIDSeed } from 'react-uid'
import { NextSeo } from 'next-seo'
import PropTypes from 'prop-types'

import { getCardItemUrl } from '@hmn/coolinarika-web-core/helpers'
import { useBreakpoints, useImageUrl, usePageUrl } from '@hmn/coolinarika-web-core/hooks'

import { stripHtml, truncate } from '../../../../helpers'
import { withErrorBoundary } from '../../../ErrorBoundary'
import { dateToSeo, StructuredData } from '../../../Seo'

const Seo = ({ item, images, structuredDataOverwrite }) => {
    // const uid = useUIDSeed()
    const [deviceType] = useBreakpoints(['xs', 'sm', 'md'], ['mobile', 'mobile', 'desktop'], null)

    const url = usePageUrl()
    const imageUrl = useImageUrl(item?.image?.id)

    const firstImageInListUrl = useImageUrl(item?.articles?.[0]?.[0]?.image?.id)

    const { seo, sDWebSite, sDWebPage, sDOrganization, sDItemList } = useMemo(() => {
        if (!item) {
            return {}
        }

        const title = truncate(stripHtml(item?.title))

        const description = truncate(stripHtml(item?.description), 160, '...')

        const author =
            'Coolinarika' || item?.author || item?.source || item?.extended_attributes?.author || 'Coolinarika'

        const updateDate = dateToSeo(item?.updated_at)

        const itemList = item?.articles?.length && item?.articles?.[0]

        const itemListNoBanner = itemList?.length && itemList?.filter(article => article.type !== 'promotion')

        return {
            seo: {
                title,
                description,
                canonical: url,
                openGraph: {
                    url,
                    title,
                    description,
                    type: 'website',
                    images: [{ width: 1200, height: 600, url: imageUrl || firstImageInListUrl }],
                    updateTime: updateDate
                },
                twitter: {
                    creator: author
                }
            },
            sDWebSite: {
                '@context': 'https://schema.org',
                '@type': 'WebSite',
                url: 'https://www.coolinarika.com',
                name: 'Coolinarika'
            },
            sDWebPage: {
                '@context': 'https://schema.org',
                '@type': 'WebPage',
                name: item?.title,
                publisher: {
                    '@type': 'Organization',
                    url: 'https://www.coolinarika.com',
                    name: 'Coolinarika',
                    logo: {
                        '@type': 'ImageObject',
                        url: item?.mainImageUrl
                    },
                    sameAs: [
                        'https://www.facebook.com/coolinarika',
                        'https://www.instagram.com/coolinarika/',
                        'https://www.youtube.com/channel/UCZ1MWvP-76e-FzhWMMW8mxg'
                    ]
                },
                url: 'https://www.coolinarika.com'
            },
            sDOrganization: {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                url: 'https://www.coolinarika.com',
                name: 'Coolinarika',
                logo: {
                    '@type': 'ImageObject',
                    url: item?.mainImageUrl
                },
                sameAs: [
                    'https://www.facebook.com/coolinarika',
                    'https://www.instagram.com/coolinarika/',
                    'https://www.youtube.com/channel/UCZ1MWvP-76e-FzhWMMW8mxg'
                ]
            },
            sDItemList: {
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                description: stripHtml(item?.description),
                url: item?.url,
                itemListElement: itemListNoBanner
                    ? itemListNoBanner.map((cardItem, index) => ({
                          '@type': 'ListItem',
                          name: cardItem?.title,
                          position: index + 1,
                          url: getCardItemUrl(cardItem)
                      }))
                    : null
            }
        }
    }, [item, deviceType, images])

    return (
        <>
            {seo && <NextSeo {...seo} />}
            {(sDWebSite || structuredDataOverwrite) && (
                <>
                    {/* //TODO: Refactor this */}
                    <StructuredData data={sDWebSite} dataOverwrite={structuredDataOverwrite} />
                    <StructuredData data={sDWebPage} dataOverwrite={structuredDataOverwrite} />
                    <StructuredData data={sDOrganization} dataOverwrite={structuredDataOverwrite} />
                    <StructuredData data={sDItemList} dataOverwrite={structuredDataOverwrite} />
                </>
            )}
        </>
    )
}
Seo.propTypes = {
    item: PropTypes.oneOfType([() => null, PropTypes.object]),
    structuredDataOverwrite: PropTypes.oneOfType([() => null, PropTypes.object]),
    images: PropTypes.oneOfType([
        () => null,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]))
    ])
}

Seo.defaultProps = {
    item: undefined,
    structuredDataOverwrite: undefined,
    images: undefined
}

export default withErrorBoundary(Seo, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // Do something with the error, e.g. log it to Sentry or console, defaults to console
        // eslint-disable-next-line no-console
        console.error('[ListingSeo]: ', error, componentStack)
    }
})
